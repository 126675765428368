import Slider from '@material-ui/core/Slider';
import { AcrylicEffectStyle, AcrylicEffect, AcrylicDemo2, AcrylicDemo3, AcrylicDemo4, AcrylicDemo5, AcrylicDemo6 } from "../../../../content/studio/fluent-ui/acrylic/acrylic-ui-example.tsx";
import acrylic_ui_str from "!!raw-loader!../../../../content/studio/fluent-ui/acrylic/acrylic-ui.tsx";
import * as React from 'react';
export default {
  Slider,
  AcrylicEffectStyle,
  AcrylicEffect,
  AcrylicDemo2,
  AcrylicDemo3,
  AcrylicDemo4,
  AcrylicDemo5,
  AcrylicDemo6,
  acrylic_ui_str,
  React
};