import React, { useEffect } from 'react'
import { CodeWithPreview } from '~components/mdx/CodeWithPreview'

export const HighlightWhenRenderView = ({ children }) => {
  let ref = React.useRef(null)
  useEffect(() => {
    if (ref.current) ref.current.style.borderColor = '#a10'
    setTimeout(() => {
      if (ref.current) ref.current.style.borderColor = 'rgba(0,0,0,0)'
    }, 300)
  }, [children])
  return (
    <div ref={ref} style={{ border: '1px solid #a10', padding: 8 }}>
      {children}
    </div>
  )
}

export class ShouldUpdateDemo extends React.Component {
  state = {
    count: 0,
    count2: 0,
  }
  shouldComponentUpdate(nextProps, nextStates) {
    if (nextStates.count === this.state.count) return false
    if (nextStates.count2 !== this.state.count2) return false
    return true
  }
  render() {
    return (
      <HighlightWhenRenderView>
        <p>tate1: {this.state.count}</p>
        <p>tate2: {this.state.count2}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          state +1 传给 子节点
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count: this.state.count })}>state 不变更新</button>
        &nbsp;
        <button onClick={() => this.setState({ count2: this.state.count2 + 1 })}>
          更新无关的状态
        </button>
      </HighlightWhenRenderView>
    )
  }
}

export const PerForDemo0 = () => (
  <CodeWithPreview
    withTitle
    preview={
      <div>
        <ShouldUpdateDemo />
      </div>
    }
  />
)

export class RCDemo1 extends React.Component {
  state = {
    count: 0,
    count2: 0,
  }
  render() {
    return (
      <HighlightWhenRenderView>
        <RCDemo2 propData={this.state.count} />
        <p>父 state1: {this.state.count}</p>
        <p>父 state2: {this.state.count2}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          父节点 state +1 传给 子节点
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count: this.state.count })}>
          父节点 state 不变更新
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count2: this.state.count2 + 1 })}>
          父节点更新无关的状态
        </button>
      </HighlightWhenRenderView>
    )
  }
}
export class RCDemo2 extends React.Component {
  state = {
    count: 0,
  }
  render() {
    return (
      <HighlightWhenRenderView>
        <p style={{ color: '#808080', fontSize: 13 }}>每次重新渲染会边框变色</p>
        <p>state: {this.state.count}</p>
        <p>props: {this.props.propData}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          子节点 state +1
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count: this.state.count })}>
          子节点 state 不变更新
        </button>
      </HighlightWhenRenderView>
    )
  }
}

export const PerForDemo1 = () => (
  <CodeWithPreview
    withTitle
    preview={
      <div>
        <RCDemo1 />
      </div>
    }
  />
)

export class RPCDemo1 extends React.PureComponent {
  state = {
    count: 0,
    count2: 0,
  }
  render() {
    return (
      <HighlightWhenRenderView>
        <RPCDemo2 propData={this.state.count} />
        <p>父 state1: {this.state.count}</p>
        <p>父 state2: {this.state.count2}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          父节点 state +1 传给 子节点
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count: this.state.count })}>
          父节点 state 不变更新
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count2: this.state.count2 + 1 })}>
          父节点更新无关的状态
        </button>
      </HighlightWhenRenderView>
    )
  }
}
export class RPCDemo2 extends React.PureComponent {
  state = {
    count: 0,
  }
  render() {
    return (
      <HighlightWhenRenderView>
        <p style={{ color: '#808080', fontSize: 13 }}>每次重新渲染会边框变色</p>
        <p>state: {this.state.count}</p>
        <p>props: {this.props.propData}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          子节点 state +1
        </button>
        &nbsp;
        <button onClick={() => this.setState({ count: this.state.count })}>
          子节点仅 state 不变更新
        </button>
      </HighlightWhenRenderView>
    )
  }
}

export const PerForDemo2 = () => (
  <CodeWithPreview
    withTitle
    preview={
      <div>
        <RPCDemo1 />
      </div>
    }
  />
)
