module.exports = [{
      plugin: require('/root/workspace/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/root/workspace/src/templates/markdown-page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"/root/workspace/plugins/remark-images/index.js","options":{"showCaptions":true,"backgroundColor":"#fafafa","maxWidth":1035,"watermark":"/root/workspace/src/images/watermark.png","watermarkGravity":"southwest","watermarkBlend":"over"}},{"resolve":"/root/workspace/plugins/remark-embedder/index.js"}],"extensions":[".mdx",".md",".markdown"]},
    },{
      plugin: require('/root/workspace/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/root/workspace/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/root/workspace/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ubug's playground","short_name":"ubug","description":"Ubug's Blog, 技术博客，分享技术背后的思考~~","start_url":"/","background_color":"#231C42","theme_color":"#4147DC","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('/root/workspace/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/root/workspace/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
