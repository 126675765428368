// novus.tsx

import React, { Component, ComponentClass, FunctionComponent, useState, useEffect } from "react"

let subscribeIndex = 0

class Novus {
  // 提供一个集中的聚合地方，这块可以不使用
  models = {};
  // 用订阅通知的模式，简单实现数据变更通知，外部可以不关心
  listeners = new Map();
  /**
   * 绑定数据模型，集中管理数据模型
   *
   * @param model 数据模型 NovusBaseModel
   */
  bindModel(model) {
    if (this.models[model.namespace]) {
      console.error("sorry, you should bind with unque namespace!!");
    }
    model.novus = this;
    this.models[model.namespace] = model;

    return () => {
      delete this.models[model.namespace]
    }
  }
  /**
   * 订阅器，订阅对应 model 改变的函数，外部可以不关心
   *
   * @param func 订阅触发函数
   * @param deps 订阅的 model，仅订阅的 model 改变后才会触发函数
   *
   * @returns 返回取消订阅的函数
   */
  subscribe = (func, deps = []) => {
    if (subscribeIndex > 10000) throw Error('---------- subscribe error')
    const id = (subscribeIndex++).toString();
    this.listeners.set(id, [func, deps]);
    return () => {
      this.listeners.delete(id);
    };
  };
  /**
   * 触发器，更新完数据仓之后必须触发才能通知 UI 变更
   *
   * @param namespace model 的命名空间 id，只会触发订阅对应 model 的 mapStatetoProps 函数。留空表示触发全部
   */
  notifyChangesListeners = (namespace) => {
    this.listeners.forEach(v => {
      if (
        namespace === undefined ||
        v[1].length === 0 ||
        v[1].includes(namespace)
      ) {
        v[0]();
      }
    });
  };
  connectProps = {
    getModel: (name) => {
      return this.models[name];
    },
    getModels: () => this.models
  };
  /**
   * 将 models state 数据转换挂载到组件上，类似 react-redux 的 connect 功能
   *
   * @param Comp 要挂载的组件，props 需求有基础的和被 connected 的
   * @param mapStatetoProps 数据转换函数
   * @param deps 依赖的 models，不在这个数组的 models 改变不会执行 mapStatetoProps 函数
   *
   * @returns NewComponent 返回新的组件，props 提示只有基础的
   */
  connect = (
    Comp,
    mapStatetoProps,
    deps = [],
  ) => {
    class OutputCom extends Component {
      constructor(props) {
        super(props);
        this.state = mapStatetoProps(novus.models, novus);
      }
      dispose;
      componentDidMount() {
        this.dispose = novus.subscribe(() => {
          let newState = mapStatetoProps(novus.models, novus);
          this.setState(newState);
        }, deps);
      }
      componentWillUnmount() {
        if (this.dispose) this.dispose();
      }

      render() {
        const allProps = {
          ...this.props,
          ...this.state,
          ...novus.connectProps
        };
        return <Comp {...allProps} />;
      }
    }

    return OutputCom;
  };
}

export const novus = new Novus();

/**
 * 一个基础的数据模型。用户可以继承此类后自己添加更新函数
 *
 * - namespace 作为唯一的 id
 * - state 作为数据储存仓
 * - setState 同步更新 state 的函数
 * - actions 用户的数据模型的更新函数，支持异步函数
 */
export class NovusBaseModel {
  /**
   * 唯一的命名空间
   */
  namespace;
  novus = novus;
  /**
   * 数据仓
   *
   * 由于继承后会 state 覆盖，所以子组件要显式指定类型
   */
  state;
  /**
   * 纯函数的数据更新函数，同步执行
   *
   * @param state 要更新的属性或者函数
   */
  setState = (state) => {
    if (typeof state === 'function') {
      const newState = state(this.state)
      this.state = { ...this.state, ...newState };
    } else {
      this.state = { ...this.state, ...state };
    }
    this.notifyChanges()
  };
  getModel = (modelName) => {
    return novus.models[modelName]
  };
  /**
   * 通知上层触发订阅的 connect 转换函数，每次修改 state 必须触发！！！
   */
  notifyChanges = () => {
    novus.notifyChangesListeners(this.namespace);
  };
  /**
   * 数据更新的函数，相当于 effects 或者 reducers 的概念集合
   */
  actions;
}
/**
 * 提供 hook 函数，在函数组件中非常简洁的使用
 *
 * @template T
 * @param {(models: TModelsPool, app: Novus) => T} mapStatetoHooks 怎么从 models 中拿到状态
 * @param {string[]} [deps] 依赖哪些 models 的变更通知
 * @returns {T}
 */
export const useNovus = function (mapStatetoHooks, deps) {
  let [state, setState] = useState(mapStatetoHooks(novus.models, novus))
  useEffect(() => {
    const dispose = novus.subscribe(() => {
      setState(mapStatetoHooks(novus.models, novus));
    }, deps);

    return () => {
      dispose()
    }
  }, [])

  return state
}


/**
 * 封装一个组件，默认携带 novus 属性，方便引用
 *
 * @export
 * @class NovusComponent
 * @extends {(Component<P & TConnectProps, S, SS>)}
 * @template P
 * @template S
 * @template SS
 */
export class NovusComponent extends Component {
  novus = novus
}

export default Novus
