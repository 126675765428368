// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("/root/workspace/.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("/root/workspace/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-pagination-js": () => import("/root/workspace/src/templates/blog-pagination.js" /* webpackChunkName: "component---src-templates-blog-pagination-js" */),
  "component---src-templates-studio-page-js": () => import("/root/workspace/src/templates/studio-page.js" /* webpackChunkName: "component---src-templates-studio-page-js" */),
  "component---src-templates-slide-js": () => import("/root/workspace/src/templates/slide.js" /* webpackChunkName: "component---src-templates-slide-js" */),
  "component---src-pages-404-js": () => import("/root/workspace/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("/root/workspace/src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-categories-js": () => import("/root/workspace/src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-codes-js": () => import("/root/workspace/src/pages/codes.js" /* webpackChunkName: "component---src-pages-codes-js" */),
  "component---src-pages-index-js": () => import("/root/workspace/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keywords-js": () => import("/root/workspace/src/pages/keywords.js" /* webpackChunkName: "component---src-pages-keywords-js" */),
  "component---src-pages-readings-js": () => import("/root/workspace/src/pages/readings.js" /* webpackChunkName: "component---src-pages-readings-js" */),
  "component---src-pages-slides-js": () => import("/root/workspace/src/pages/slides.js" /* webpackChunkName: "component---src-pages-slides-js" */),
  "component---src-pages-studio-js": () => import("/root/workspace/src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-talks-js": () => import("/root/workspace/src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-about-mdx": () => import("/root/workspace/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-resume-mdx": () => import("/root/workspace/src/pages/resume.mdx" /* webpackChunkName: "component---src-pages-resume-mdx" */),
  "component---src-pages-search-mdx": () => import("/root/workspace/src/pages/search.mdx" /* webpackChunkName: "component---src-pages-search-mdx" */),
  "component---src-pages-tools-mdx": () => import("/root/workspace/src/pages/tools.mdx" /* webpackChunkName: "component---src-pages-tools-mdx" */)
}

