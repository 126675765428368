// todoModel.ts

import {NovusBaseModel, novus} from './novus'

const todoDto = {
  sync: async list => {
    await new Promise(r => setTimeout(r, 1000))
  },
}

// 使用继承类的形式限制属性和方法
class TodoModel extends NovusBaseModel {
  namespace = 'todoModel'
  constructor() {
    super()
    this.state = {
      groups: [],
      list: [],
      filters: {
        tags: [],
        statuses: [],
      },
    }
  }
  // 自定义状态更新函数
  syncData = async list => {
    this.setState({loading: true})
    await todoDto.sync(list)
    this.setState({list, loading: false})
  }
  // actions 集合，可以调用异步函数，约定为修改状态的逻辑，提供数据操作方法
  actions = {
    create: async (content, tags = []) => {
      const newTodo = {
        tags,
        content,
        status: 1,
      }
      await this.syncData([newTodo, ...this.state.list])
    },
    update: async (index, todo) => {
      const newTodo = {
        ...this.state.list[index],
        ...todo,
      }
      await this.syncData([
        ...this.state.list.slice(0, index - 1),
        newTodo,
        ...this.state.list.slice(index + 1),
      ])
    },
    delete: async index => {
      await this.syncData([...this.state.list.slice(0, index), ...this.state.list.slice(index + 1)])
    },
  }
}

export const model = new TodoModel()

novus.bindModel(model)
