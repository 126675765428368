import { withStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';

const acrylicStyle: CSSProperties = {
  backdropFilter: 'blur(30px) brightness(80%) saturate(160%)',
}

export const AcrylicAlert = withStyles({
  root: {
    ...acrylicStyle,
  },
  filledError: {
    backgroundColor: 'rgba(244, 67, 54, .4)',
  },
  filledWarning: {
    backgroundColor: 'rgba(255, 152, 0, .4)',
  },
  filledInfo: {
    backgroundColor: 'rgba(33, 150, 243, .4)',
  },
  filledSuccess: {
    backgroundColor: 'rgba(76, 175, 80, .4)',
  }
})(Alert);


export const AcrylicTooltip = withStyles({
  popper: {
    willChange: 'auto !important' // transform 会影响 back-drop
  },
  tooltip: {
    ...acrylicStyle,
    backgroundColor: 'rgba(97, 97, 97, 0.5)'
  },
})(Tooltip);

export const AcrylicPopover = withStyles({
  paper: {
    ...acrylicStyle,
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
})(Popover);

export const AcrylicMenu = withStyles({
  paper: {
    ...acrylicStyle,
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
})(Menu);


export const AcrylicDialog = withStyles({
  paper: {
    ...acrylicStyle,
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
})(Dialog);
