/* eslint-disable import/extensions */
/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react'
import {RunTSX} from '~components/mdx/RunTSX'

import bubbleStr1 from '!!raw-loader!./methods/bubble_m1.ts'
import bubbleStr2 from '!!raw-loader!./methods/bubble_m2.ts'
import bubbleStr3 from '!!raw-loader!./methods/bubble_m3.ts'
import insertionStr1 from '!!raw-loader!./methods/Insertion.ts'
import selectionStr1 from '!!raw-loader!./methods/selection.ts'
import shellStr1 from '!!raw-loader!./methods/shell.ts'
import mergeStr1 from '!!raw-loader!./methods/merge.ts'
import mergeStr2 from '!!raw-loader!./methods/merge_m2.ts'

// 堆排序
// 快速排序

import countStr from '!!raw-loader!./methods/count.ts'
// 非比较排序 ↓
// 计数排序
// 桶排序
// 基数排序

const Style = {
  textAlign: 'center',
  lineHeight: '250px',
  background: '#eaeaea',
}

export class SortDemos extends React.Component {
  constructor() {
    super()
    this.state = {
      currentKey: '-',
      codeMap: {
        bubble: ['bubble 经典冒泡', bubbleStr1],
        bubble2: ['bubble 停止优化', bubbleStr2],
        bubble3: ['bubble 跳过优化', bubbleStr3],
        insertion: ['insertion 插入排序', insertionStr1],
        selection: ['selection 选择排序', selectionStr1],
        shell: ['shell 希尔排序', shellStr1],
        merge: ['merge 合并排序 递归', mergeStr1],
        merge2: ['merge 合并排序 遍历', mergeStr2],
        merge2: ['Counting 计数排序', countStr],
      },
    }
  }
  componentDidMount() {
    this.detectHash()
    window.addEventListener('hashchange', this.detectHash, false)
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', this.detectHash, false)
  }
  detectHash = () => {
    const currentKey = location.hash.replace(/^#/, '')
    if (this.state.codeMap[currentKey]) {
      this.setState({currentKey})
    }
  }
  updateKeyName = currentKey => {
    location.hash = currentKey
    this.setState({currentKey: ''})
    setTimeout(() => {
      location.hash = currentKey
      this.setState({currentKey})
    }, 200)
  }
  render() {
    return (
      <div>
        <div style={{marginBottom: 10}}>
          {Object.keys(this.state.codeMap).map(key => (
            <button
              key={key}
              onClick={() => {
                this.updateKeyName(key)
              }}
              style={{
                margin: '0 4px 4px 0',
                backgroundColor: key === this.state.currentKey ? '#25805f' : '#17A974',
              }}
            >
              {this.state.codeMap[key][0]}
            </button>
          ))}
        </div>
        {this.state.currentKey == '' ? (
          <div style={Style}>Switching...</div>
        ) : this.state.currentKey == '-' ? (
          <div style={Style}>请选择</div>
        ) : (
          <RunTSX height={600} codeStr={this.state.codeMap[this.state.currentKey][1]} />
        )}
      </div>
    )
  }
}
