/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useCallback} from 'react'
import {CodeWithPreview} from '~components/mdx/CodeWithPreview'

export const Follow = ({followed}) => {
  return (
    <div className="follow_wrap">
      <div>{followed ? '已关注' : '未关注'}</div>
      <div className={`follow follow__${followed ? 'on' : 'off'}`}>
        <div className="follow_inner" />
      </div>
    </div>
  )
}
export const code1 = `const Follow = ({ followed }) => {
  return (
    <div className="follow_wrap">
      <div>{followed ? '已关注' : '未关注'}</div>
      <div className={\`follow follow__\${followed ? 'on' : 'off'}\`}>
        <div className="follow_inner" />
      </div>
    </div>
  )
}`

const delay = async () => {
  return new Promise(r => setTimeout(r, 1000))
}

const store = {
  val: true,
  listener: function() {},
  listen(func) {
    this.listener = func
    this.listener(this.val)
    return () => {}
  },
  async update(newVal) {
    await delay()
    this.val = newVal
    this.listener(this.val)
  },
}

class Follow2 extends React.PureComponent {
  state = {
    loading: false,
    followed: false,
  }
  listenerDispose
  componentDidMount() {
    this.listenerDispose = store.listen(status => {
      this.setState({followed: status})
    })
  }
  componentWillUnmount() {
    this.listenerDispose()
  }
  switchStatus = async () => {
    this.setState({loading: true})
    await store.update(!this.state.followed)
    this.setState({loading: false})
  }
  render() {
    const {loading, followed} = this.state
    return (
      <div className="follow_wrap">
        <div>{loading ? 'loading' : followed ? '已关注' : '未关注'}</div>
        <div
          className={`follow follow__${loading ? 'switching' : followed ? 'on' : 'off'}`}
          onClick={this.switchStatus}
        >
          <div className="follow_inner" />
        </div>
      </div>
    )
  }
}

export class Demo2 extends React.Component {
  componentWillMount() {
    this.setState({on: true})
  }
  render() {
    return (
      <CodeWithPreview
        withTitle
        column
        codes={[
          {
            lang: 'jsx',
            code: `class Follow2 extends React.PureComponent {
  state = {
    loading: false,
    followed: false,
  }
  listenerDispose
  componentDidMount() {
    this.listenerDispose = store.listen(status => {
      this.setState({followed: status})
    })
  }
  componentWillUnmount() {
    this.listenerDispose()
  }
  switchStatus = async () => {
    this.setState({loading: true})
    await store.update(!this.state.followed)
    this.setState({loading: false})
  }
  render() {
    const {loading, followed} = this.state
    return (
      <div className="follow_wrap">
        <div>{loading ? 'loading' : followed ? '已关注' : '未关注'}</div>
        <div
          className={\`follow follow__\${loading ? 'switching' : followed ? 'on' : 'off'}\`}
          onClick={this.switchStatus}
        >
          <div className="follow_inner" />
        </div>
      </div>
    )
  }
}`,
          },
        ]}
        preview={
          <div>
            <Follow2 />
          </div>
        }
      />
    )
  }
}

function useModel(func) {
  let [loading, setLoading] = useState(false)
  let [followed, setFollowed] = useState(false)

  let updateModel = useCallback(
    async val => {
      console.log(val)
      setLoading(true)
      await delay()
      setLoading(false)
      setFollowed(val)
    },
    [followed],
  )

  return [followed, updateModel, loading]
}

function Follow3() {
  let [followed, updateModel, loading] = useModel(store => store.status)
  return (
    <div className="follow_wrap">
      <div>{loading ? 'loading' : followed ? '已关注' : '未关注'}</div>
      <div
        className={`follow follow__${loading ? 'switching' : followed ? 'on' : 'off'}`}
        onClick={updateModel.bind(null, !followed)}
      >
        <div className="follow_inner" />
      </div>
    </div>
  )
}

export class Demo3 extends React.Component {
  componentWillMount() {
    this.setState({on: true})
  }
  render() {
    return (
      <CodeWithPreview
        withTitle
        column
        codes={[
          {
            lang: 'jsx',
            code: `function Follow3() {
  let [followed, updateModel, loading] = useModel(store => store.status)
  return (
    <div className="follow_wrap">
      <div>{loading ? 'loading' : followed ? '已关注' : '未关注'}</div>
      <div
        className={\`follow follow__\${loading ? 'switching' : followed ? 'on' : 'off'}\`}
        onClick={updateModel.bind(null, !followed)}
      >
        <div className="follow_inner" />
      </div>
    </div>
  )
}`,
          },
        ]}
        preview={
          <div>
            <Follow3 defaultEnabled={this.state.on} onValueChange={v => this.setState({on: v})} />
          </div>
        }
      />
    )
  }
}

export const styleStr = `.follow_wrap{
  display: flex;
  width: 200px;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-radius: 40px;
  padding: 10px 10px 10px 15px;
  font-size: 16px;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}
.follow{
  position: relative;
  width: 40px;
  height: 22px;
  background: #dfdfdf;
  border-radius: 20px;
  cursor: pointer;
  transition: background .2s;
}
.follow_inner{
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 2px;
  background: #fff;
  border-radius: 50%;
  transition: left .2s, width .2s;
}
.follow__on{ background: #2196F3; }
.follow__on .follow_inner{ left: 50%; }
.follow:active .follow_inner{ width: 20px; }
.follow__on:active .follow_inner{ left: calc(50% - 2px); }
.follow__switching .follow_inner{ left: calc(25% + 2px); }`
