import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { AcrylicDemo5, AcrylicEffect, AcrylicEffect2, AcrylicEffect3, AcrylicEffectStyle } from "../../../../content/studio/fluent-ui/acrylic/acrylic-ui-example.tsx";
import * as React from 'react';
export default {
  Slider,
  TextField,
  ArchiveOutlinedIcon,
  DeleteForeverOutlinedIcon,
  BugReportOutlinedIcon,
  FlagOutlinedIcon,
  DraftsOutlinedIcon,
  DeleteSweepIcon,
  PostAddIcon,
  AcrylicDemo5,
  AcrylicEffect,
  AcrylicEffect2,
  AcrylicEffect3,
  AcrylicEffectStyle,
  React
};