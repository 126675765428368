/* eslint-disable no-new-func */
import React from 'react'
import deepcopy from 'deepcopy'

import monacoLoad from './code-run/monaco-loader'
import { CodeWithPreview } from './CodeWithPreview'

export class LeetCodePlayground extends React.Component {
  state = {
    codeStr: this.props.codeStr,
    runner: new Function(this.props.codeStr)(),
    results: [],
  }
  editorRef
  editor
  componentDidMount() {
    monacoLoad.ensureMonacoIsLoaded().then(() => {
      if (this.editorRef) {
        this.editor = monaco.editor.create(this.editorRef, {
          theme: window.matchMedia("(prefers-color-scheme: dark)") ? 'vs-dark' : 'vs-light',
          value: this.state.codeStr,
          language: 'javascript',
        })
      }
    })
    this.runCases()
  }
  runCases = () => {
    this.setState({ results: [] })
    const newResult = deepcopy(this.props.inputs).map((input, index) => {
      const startime = new Date()
      for (let i = 0; i < this.props.times; i++) {
        this.state.runner(input)
      }
      const endtime = new Date()

      return [
        this.props.inputStrs ? this.props.inputStrs[index] : input,
        this.props.output(this.state.runner(input)),
        `${endtime.getTime() - startime.getTime()}ms / ${this.props.times}`,
      ]
    })
    this.setState({ results: newResult })
  }
  strToFunc = () => {
    const codeStr = this.editor.getValue()
    this.setState(
      {
        runner: new Function(codeStr)(),
        codeStr,
      },
      () => {
        this.runCases()
      },
    )
  }
  render() {
    return (
      <CodeWithPreview
        isWhiteTheme
        withPreviewTitle
        preview={
          <div
            className="leet-code-playground-demo"
            style={{
              width: '100%',
            }}
          >
            <div
              ref={ref => {
                this.editorRef = ref
              }}
              className="leet-code-playground-code"
              style={{
                background: '#dedede',
                minHeight: this.props.height || 400,
                overFlow: 'hide',
                width: '100%',
              }}
            />
            <div
              className="leet-code-playground-result"
              style={{
                position: 'relative',
                minHeight: 100,
                borderTop: '1px solid #f5f5f5',
                // background: '#fff',
                color: '#333',
                // background: '#fff',
                // color: '#565656',
                padding: 20,
              }}
            >
              <div
                className="leet-code-playground-run"
                onClick={() => this.strToFunc()}
                onKeyDown={() => { }}
              >
                &gt;
              </div>
              {this.state.runner && (
                <table style={{ backgroundColor: 'transparent' }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>input</th>
                      <th>output</th>
                      <th>takes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.results.map((result, index) => {
                      return (
                        <tr key={result[0]}>
                          <td>{index}</td>
                          <td>{result[0]}</td>
                          <td>{result[1]}</td>
                          <td>{result[2]}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        }
      />
    )
  }
}
