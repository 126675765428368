import { Follow, Demo2, Demo3, code1, styleStr } from "../../../../content/blog/react-hooks-vs-class/components";
import { ExampleTodoExample } from "../../../../content/blog/novus/example.js";
import novus_hooks_novusCodeStr2 from "!!raw-loader!../../../../content/blog/novus/example.tsx";
import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';
export default {
  Follow,
  Demo2,
  Demo3,
  code1,
  styleStr,
  ExampleTodoExample,
  novus_hooks_novusCodeStr2,
  useState,
  useEffect,
  useCallback,
  React
};