import React, {SFC, CSSProperties} from 'react'
import {css} from '@emotion/core'
import {RevealContainer} from './reveal-ui'
import Snackbar from '@material-ui/core/Snackbar'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import InboxIcon from '@material-ui/icons/Inbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import Search from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import {
  Button,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from '@material-ui/core'

const ExampleContainer: SFC = ({children}) => {
  return (
    <React.Fragment>
      <div>{children}</div>
      <style>{`
@keyframes ani-bg-acrylic {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
`}</style>
    </React.Fragment>
  )
}

export const RevealDemo = () => {
  return (
    <ExampleContainer>
      <div
        css={css`
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {new Array(24).fill(0).map((v, i) => (
          <RevealContainer
            key={i}
            revealSize={200}
            revealColor="#fff"
            borderColor="#fff"
            borderWidth={3}
            style={{
              width: 100,
              margin: 4,
              height: 100,
              background: '#1794e0',
            }}
            childStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            {i}
          </RevealContainer>
        ))}
      </div>
    </ExampleContainer>
  )
}
