import novus_novusCodeStr from "!!raw-loader!../../../../content/blog/novus/novus.tsx";
import novus_novusCodeStrSimple from "!!raw-loader!../../../../content/blog/novus/novus_simple.tsx";
import novus_novusExampleStr1 from "!!raw-loader!../../../../content/blog/novus/todoModel.ts";
import novus_novusExampleStr from "!!raw-loader!../../../../content/blog/novus/example.tsx";
import { ExampleTodoExample } from "../../../../content/blog/novus/example.js";
import * as React from 'react';
export default {
  novus_novusCodeStr,
  novus_novusCodeStrSimple,
  novus_novusExampleStr1,
  novus_novusExampleStr,
  ExampleTodoExample,
  React
};