import React, { useCallback, useEffect } from 'react'
import ClipboardJS from 'clipboard'

import Code from './code'

export const CodeWithPreview = ({
  codes = [],
  code,
  lang,
  meta,
  preview,
  detach = false,
  column = false,
  hideCode = false,
  isWhiteTheme = false,
  withTitle,
  titleBar,
  previewBg = 'var(--color-codepreview-bg-component)',
  textColor = 'var(--color-codepreview-text-component)',
}) => {
  codes = !codes && code ? [{ lang, code, meta }] : codes

  const [copyText, setCopyText] = React.useState('Copy')
  const [whiteTheme, setWhiteTheme] = React.useState(true)

  const copyCode = useCallback(code => {
    try {
      copyToClipboard(code)
      setCopyText('Copied')
      setTimeout(() => {
        setCopyText('Copy')
      }, 3000)
    } catch (error) {
      setCopyText('Sorry, Copy Failed!')
      setTimeout(() => {
        setCopyText('Copy')
      }, 3000)
    }
  }, [])

  const [expanded, setExpanded] = React.useState(true)
  const switchExpand = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])
  useEffect(() => {
    if (hideCode) setExpanded(false)
  }, [hideCode])

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)")) {
      setWhiteTheme(false)
    }
  }, [])

  return (
    <div
      className={`cd-w-pv ${detach ? 'cd-w-pv-detach' : ''} ${column ? 'cd-w-pv-column' : ''} ${whiteTheme ? 'cd-w-pv-white' : ''
        } ${hideCode ? 'cd-w-pv-hide-code' : ''} ${expanded ? 'cd-w-pv-expanded' : ''}`}
    >
      {withTitle && <div className="cd-w-pv-title">{titleBar}</div>}
      {preview && (
        <div className="cd-w-pv_preview">
          {/* {withTitle && (
            <div className={`cd-w-pv-title ${codes.length > 0 && !hideCode ? 'no-dots' : ''}`} />
          )} */}
          <div className="cd-w-pv_preview-content" style={{ background: previewBg, color: textColor }}>
            {preview}
            {codes.length > 0 && <div className="cd-w-pv_preview-expand" onClick={switchExpand}>
              {expanded ? 'Hide Code' : 'Show Code'}
            </div>}
          </div>
          {/* <div className="cd-w-pv_pv-reload" onClick={() => setReloadText('Reloaded')}>{reloadText}</div> */}
        </div>
      )}
      {codes.length > 0 && (
        <div className="cd-w-pv_codes">
          {codes.map((code_, ind) => (
            <div key={ind} className="cd-w-pv_code">
              <Code
                codeString={code_.code}
                language={code_.lang || 'jsx'}
                metastring={code_.meta || ''}
              />
              <div
                onClick={copyCode.bind(null, code_.code)}
                className="cd-w-pv_code-copy"
                code={code}
              >
                {copyText}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CodeWithPreview

const copyToClipboard = str => {
  const el = document.createElement('textarea') // Create a <textarea> element
  el.value = str // Set its value to the string that you want copied
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px' // Move outside the screen to make it invisible
  document.body.appendChild(el) // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before
  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el) // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges() // Unselect everything on the HTML document
    document.getSelection().addRange(selected) // Restore the original selection
  }
}
