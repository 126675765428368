import React, {SFC, useCallback, useEffect, useState, useRef} from 'react'
import {css} from '@emotion/core'

// import {withStyles} from '@material-ui/core/styles'
// import {CSSProperties} from '@material-ui/core/styles/withStyles'
// import Alert from '@material-ui/lab/Alert'
// import Tooltip from '@material-ui/core/Tooltip'
// import Popover from '@material-ui/core/Popover'
// import Menu from '@material-ui/core/Menu'
// import Dialog from '@material-ui/core/Dialog'

type RevealHighlightProps = {
  pos?: string
  revealSize?: number
  style?: React.CSSProperties
  color?: string
}

const posStyle: React.CSSProperties = {
  position: 'absolute',
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}

export const RevealHighlight: SFC<RevealHighlightProps> = React.memo(
  ({style, pos, revealSize = 200, color = '#fff'}) => {
    const revealRef = useRef<HTMLDivElement>(null)
    const mainRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const mousemoveHandler = (ev: MouseEvent) => {
        if (mainRef.current && revealRef.current) {
          const rect = mainRef.current.getBoundingClientRect()
          revealRef.current.style.transform = `translate(${ev.x - rect.left}px, ${ev.y -
            rect.top}px)`
        }
      }
      window.addEventListener('mousemove', mousemoveHandler, false)
      return () => {
        window.removeEventListener('mousemove', mousemoveHandler)
      }
    }, [])

    return (
      <div style={{...style, position: 'relative', width: '100%', height: '100%'}} ref={mainRef}>
        <div style={{...posStyle}}>
          <div
            className={`${pos}-reveal-box`}
            ref={revealRef}
            style={{
              backgroundImage: `radial-gradient(circle closest-side,${color},transparent)`,
              position: 'absolute',
              width: revealSize,
              height: revealSize,
              marginTop: -revealSize / 2,
              marginLeft: -revealSize / 2,
              transform: `translate(-50%, -50%)`,
              mixBlendMode: 'soft-light',
              left: 0,
              top: 0,
            }}
          ></div>
        </div>
      </div>
    )
  },
)

type RevealContainerProps = {
  style?: React.CSSProperties
  childStyle?: React.CSSProperties
  children?: React.ReactNode
  revealColor: string
  revealSize: number
  borderWidth: number
  borderColor: string
}

export const RevealContainer: SFC<RevealContainerProps> = React.memo(
  ({children, style, childStyle = {}, revealSize, borderWidth, borderColor, revealColor}) => {
    return (
      <div
        style={{...style, position: 'relative'}}
        css={css`
          &:hover {
            .hoverlight-reveal-box {
              background-color: ${revealColor} !important;
              transform: translate(50%, 50%) !important;
            }
            .border-reveal-box {
              background-color: ${borderColor} !important;
              transform: translate(50%, 50%) !important;
            }
          }
        `}
      >
        <div style={posStyle} className="backplate"></div>

        <div style={posStyle} className="hoverlight">
          <RevealHighlight revealSize={revealSize} pos="hoverlight" color={revealColor} />
        </div>

        <div style={{...posStyle, ...childStyle}}>{children}</div>

        <div style={{...posStyle, zIndex: 3}} className="borders">
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: `calc(100% - ${borderWidth}px)`,
              height: borderWidth,
            }}
          >
            <RevealHighlight revealSize={revealSize} pos="border" color={borderColor} />
          </div>
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              width: borderWidth,
              height: `calc(100% - ${borderWidth}px)`,
            }}
          >
            <RevealHighlight revealSize={revealSize} pos="border" color={borderColor} />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: `calc(100% - ${borderWidth}px)`,
              height: borderWidth,
            }}
          >
            <RevealHighlight revealSize={revealSize} pos="border" color={borderColor} />
          </div>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: borderWidth,
              height: `calc(100% - ${borderWidth}px)`,
            }}
          >
            <RevealHighlight revealSize={revealSize} pos="border" color={borderColor} />
          </div>
        </div>
      </div>
    )
  },
)
