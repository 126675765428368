import React from 'react'
import ReactDOM from 'react-dom'

import monacoLoad from './code-run/monaco-loader'
import { CodeWithPreview } from './CodeWithPreview'

export class RunTSX extends React.Component {
  runner = () => { }
  resultContainerRef
  editorRef
  componentDidMount() {
    window.React = React
    window.ReactDOM = ReactDOM
    Promise.all([monacoLoad.ensureMonacoIsLoaded(), import('./code-run/code-run-support')]).then(
      libs => {
        if (this.editorRef) {
          libs[1]
            .default(
              this.editorRef,
              this.resultContainerRef,
              this.props.codeStr,
              !window.matchMedia("(prefers-color-scheme: dark)"),
            )
            .then(runner => {
              this.runner = runner
              this.runCases()
            })
        }
      },
    )
  }
  runCases = () => {
    this.runner()
  }
  render() {
    return (
      <CodeWithPreview
        isWhiteTheme={this.props.isWhiteTheme}
        withPreviewTitle
        previewBg="#f5f9fa"
        preview={
          <div
            className="leet-code-playground-demo"
            style={{
              width: '100%',
            }}
          >
            <div
              ref={ref => {
                this.editorRef = ref
              }}
              className="leet-code-playground-code"
              style={{
                background: '#dedede',
                minHeight: this.props.height || 400,
                overFlow: 'hide',
                width: '100%',
              }}
            />
            <div
              className="leet-code-playground-result"
              style={{
                position: 'relative',
                minHeight: 100,
                // borderTop: '1px solid #f5f5f5',
                background: this.props.isWhiteTheme ? '#dee9ec' : '#666',
                color: this.props.isWhiteTheme ? '#333' : '#dfdfdf',
                // background: '#fff',
                // color: '#565656',
                padding: 20,
              }}
            >
              <div
                className="leet-code-playground-run"
                onClick={() => this.runCases()}
                onKeyDown={() => { }}
              >
                &gt;
              </div>
              <div
                ref={ref => {
                  this.resultContainerRef = ref
                }}
                style={{ backgroundColor: 'transparent' }}
              />
            </div>
          </div>
        }
      />
    )
  }
}
