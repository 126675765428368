import React from 'react'
import Img from 'gatsby-image'
import debounceFn from 'debounce-fn'
import { useStaticQuery, graphql, Link } from 'gatsby'
import matchSorter from 'match-sorter'
import theme from '../../config/theme'

function BlogPostCard({ blogpost }) {
  const { slug, title, description, keywords, banner } = blogpost
  return (
    <div
      css={{
        background: 'var(--bgcolor-post)',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: 5,
        padding: 30,
        margin: 10,
        width: 'calc(50% - 20px)'
      }}
    >
      <h2 css={{ margin: '0 0 10px' }}><Link to={slug}>{title}</Link></h2>
      {banner && <Img fluid={banner.childImageSharp.fluid} alt={keywords.join(', ')} />}
      <div css={{ margin: '16px 0 0 0' }}>{description}</div>
    </div>
  )
}

function useDebFn(cb, opts) {
  return React.useCallback(debounceFn(cb, opts), [])
}

function SearchScreen() {
  const result = useStaticQuery(
    graphql`
      query {
        blogposts: allMdx(
          sort: {fields: frontmatter___date, order: DESC}
          filter: {
            frontmatter: {published: {ne: false}}
            fileAbsolutePath: {regex: "//content/blog//"}
          }
        ) {
          edges {
            node {
              fields {
                id
                slug
                title
                categories
                keywords
                description: plainTextDescription
                banner {
                  ...bannerImage260
                }
              }
              excerpt(pruneLength: 190)
            }
          }
        }
      }
    `,
  )

  // this will be the same every time and because this re-renders on every
  // keystroke I'm pretty sure useMemo is appropriate here.
  const blogposts = React.useMemo(() => {
    return result.blogposts.edges.map(e => ({
      ...e.node.fields,
      excerpt: e.node.excerpt,
    }))
  }, [result.blogposts.edges])

  const [search, setSearch] = React.useState('')
  const searchInputRef = React.useRef()
  const filteredBlogPosts = matchSorter(blogposts, search, {
    keys: [
      'title',
      'categories',
      'keywords',
      { key: 'description', threshold: matchSorter.rankings.CONTAINS },
      { key: 'excerpt', threshold: matchSorter.rankings.CONTAINS },
    ],
  })

  return (
    <div>
      <div>
        <h1><label htmlFor="search-input">
          🔍 搜索文章
        </label></h1>

        <input
          id="search-input"
          css={{ width: '100%', background: 'var(--bgcolor-post)', padding: '10px', margin: '10px 0 10px 0', color: 'var(--text-color-body)' }}
          ref={searchInputRef}
          onChange={useDebFn(() => setSearch(searchInputRef.current.value), {
            wait: 200,
          })}
          type="search"
          placeholder="搜索关键词"
          autoFocus
        />
      </div>
      <small>
        {`如果搜索不到你想找的内容，可以尝试 `}
        <a href="https://www.google.com/search?q=site%3Aubug.io%2F">使用 Google</a>
        {'.'}
      </small>
      <div
        css={{
          marginTop: 20,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {filteredBlogPosts.map(blogpost => (
          <BlogPostCard key={blogpost.id} blogpost={blogpost} />
        ))}
      </div>
    </div>
  )
}

export default SearchScreen
