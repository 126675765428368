// TodoExample.tsx

import React, { useCallback } from 'react'
import { model as todoModel } from './todoModel.js'
import { useNovus } from './novus.js'

export const ExampleTodoExample = props => {
  let [list, loading] = useNovus(
    models => {
      return [models.todoModel.state.list, models.todoModel.state.loading]
    },
    ['todoModel'],
  )

  const deleter = useCallback(async index => {
    await todoModel.actions.delete(index)
  }, [])
  const adder = useCallback(async () => {
    await todoModel.actions.create(new Date().toTimeString())
  }, [])

  return (
    <div>
      <br />
      <button disabled={loading} onClick={adder}>
        {loading ? 'loading' : 'ADD'}
      </button>
      <br />
      <br />
      <table>
        <tbody>
          {list.map((todo, index) => {
            return (
              <tr key={todo.content}>
                <td style={{ paddingLeft: 10 }}>{todo.content}</td>
                <td style={{ paddingRight: 10 }}>
                  <button onClick={deleter.bind(null, index)}>
                    关闭
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
